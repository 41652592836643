import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _59636a46 = () => interopDefault(import('../src/pages/sale/index.vue' /* webpackChunkName: "pages/sale/index" */))
const _146aea53 = () => interopDefault(import('../src/pages/schedule.vue' /* webpackChunkName: "pages/schedule" */))
const _13204768 = () => interopDefault(import('../src/pages/tablo.vue' /* webpackChunkName: "pages/tablo" */))
const _3930d9fe = () => interopDefault(import('../src/pages/passengers/izmenit-pasportnyye-dannyye.vue' /* webpackChunkName: "pages/passengers/izmenit-pasportnyye-dannyye" */))
const _bb5fe846 = () => interopDefault(import('../src/pages/passengers/naiti-oplatit-zakaz.vue' /* webpackChunkName: "pages/passengers/naiti-oplatit-zakaz" */))
const _30993534 = () => interopDefault(import('../src/pages/passengers/proverit-skachat-bilet.vue' /* webpackChunkName: "pages/passengers/proverit-skachat-bilet" */))
const _48385584 = () => interopDefault(import('../src/pages/passengers/zayavka-na-vozvrat-obmen-bileta.vue' /* webpackChunkName: "pages/passengers/zayavka-na-vozvrat-obmen-bileta" */))
const _151e0153 = () => interopDefault(import('../src/pages/sale/_.vue' /* webpackChunkName: "pages/sale/_" */))
const _3c47bc46 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _aa09595a = () => interopDefault(import('../src/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/sale",
    component: _59636a46,
    meta: {"name":"flights","layout":"index","transition":"fade"},
    name: "sale"
  }, {
    path: "/schedule",
    component: _146aea53,
    meta: {"name":"schedule","layout":"index"},
    name: "schedule"
  }, {
    path: "/tablo",
    component: _13204768,
    meta: {"name":"tablo","layout":"index"},
    name: "tablo"
  }, {
    path: "/passengers/izmenit-pasportnyye-dannyye",
    component: _3930d9fe,
    meta: {"name":"bids-request","layout":"index","transition":"fade"},
    name: "passengers-izmenit-pasportnyye-dannyye"
  }, {
    path: "/passengers/naiti-oplatit-zakaz",
    component: _bb5fe846,
    meta: {"name":"bids-request","layout":"index","transition":"fade"},
    name: "passengers-naiti-oplatit-zakaz"
  }, {
    path: "/passengers/proverit-skachat-bilet",
    component: _30993534,
    meta: {"name":"bids-request","layout":"index","transition":"fade"},
    name: "passengers-proverit-skachat-bilet"
  }, {
    path: "/passengers/zayavka-na-vozvrat-obmen-bileta",
    component: _48385584,
    meta: {"name":"bids-request","transition":"fade","layout":"index"},
    name: "passengers-zayavka-na-vozvrat-obmen-bileta"
  }, {
    path: "/sale/*",
    component: _151e0153,
    meta: {"name":"flights-dynamic","layout":"index","transition":"fade"},
    name: "sale-all"
  }, {
    path: "/",
    component: _3c47bc46,
    meta: {"name":"index","layout":"index"},
    name: "index"
  }, {
    path: "/*",
    component: _aa09595a,
    meta: {"name":"dynamic-route","layout":"index","transition":"fade"},
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
